import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css'; // import Font Awesome CSS
import { SessionProvider } from 'next-auth/react';
import Router from 'next/router';
import NProgress from 'nprogress'; //nprogress module
import 'nprogress/nprogress.css'; //styles of nprogress
import { AppContextProvider } from '@/context/appContext';
import '../scss/app.scss';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import * as ga from '../lib/ga';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
    const getLayout = Component.getLayout || ((page) => page);

    // Google Analytics 4
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url) => {
            window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
                page_path: url,
            });
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        
            <SessionProvider session={session}>
                <AppContextProvider>
                <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTHA_SITE_KEY}
                    scriptProps={{
                        defer: true,
                        appendTo: "body",
                        nonce: undefined,
                    }}>
                    {getLayout(<Component {...pageProps} />)}
                    </GoogleReCaptchaProvider>
                </AppContextProvider>
            </SessionProvider>
        
    );
}

export default MyApp;
